/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr.css';  

.page-wrapper .page-body-wrapper footer a {
  color: #a5a5a5;
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

.image-logo-size{
  max-width:50%;
}


div.ngx-editor-textarea a {
  color: #ff9f40 !important;
}

div.ngx-editor-textarea ul {
  padding-left: inherit !important;
  list-style-type: disc !important;
  margin-left: 15px;
}

body.light{
  div.news-content, div.covid-content, div.event-content, div.blog-content {
    a {
      color: #2e3efcd9;
      text-decoration: underline; 
      &:hover{
        color: #2e3efcd9 !important;
      }
    }
  }
}

body.dark-only {
  div.news-content, div.covid-content, div.event-content, div.blog-content {
    a {
      color: #01BAF3;
      text-decoration: underline;
    }
  }
}

div.news-content, div.covid-content, div.event-content, div.blog-content {
  ul {
    list-style-type: disc !important;
    margin-left: 20px !important;
  }
}
