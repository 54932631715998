.center-inline {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

svg.feather.feather-menu, svg.feather.feather-chevron-left {
  width: 32px;
  height: 60px;
  margin-top: 0;
  color: #000006;
}

svg.feather.feather-chevron-left:hover {
  color: #000006;
}

svg.feather.feather-chevron-down {
  color: #5a5959 !important;
}

svg.feather.feather-bell {
  color: #ffffff !important;
  width: 25px !important;
  height: 25px !important;
}

/*.badge-default {
  background-color: #FA2727;
  color: white;
}*/

.badge-orange {
  background-color: #ff9f40;
  color: white;
}

.notification-badge {
  margin-left: -10px;
  margin-top: -10px;
  padding: 0.2em 0.4em;
}

.invalid-feedback.is-invalid {
  display: block;
}

/*This is to prevent Last Pass icons from persisting on the screen after logging in */
div[id^="__lpform"] {
  display: none;
}

.custom-breadcrumb {
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 500;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
  }
}


// hack wrapper to make the magnify glass icon didn't move outside the input control

.filter-cont {
  margin: -30px;
}

.filter-cont .filter-header {
  padding: 30px;
}

.search-form {
  padding: 0 0 10px 0;
}

.pagination .page-item .page-link {
  border-radius: 0 !important;
  border-style: none;
}

.pagination .page-link {
  background-color: transparent;
  color: #fff;
}

.page-item.active .page-link {
  background-color: transparent;
  color: #01BAF3;
}

.custom-photo-preview-box, .custom-photo-preview-box-new {
  padding: 2px;
}

.cover-photo {
  border: dashed 2px #ff9f40;
}

.gallery-progress-bar-custom {
  background-color: #ff9f40;
}

.carousel-indicators, .carousel-control-next, .carousel-control-prev  {
  -webkit-filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 1));
  filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 1));
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
 
  background-image: none;
}

.carousel-control-next-icon::after {
  content: "\f138";  /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
    position:absolute;
    font-size: 200%;
    color: #fff !important;

    @media screen and (min-width: 0px) and (max-width: 545px) {
        left: -5px;      
    }
}

.carousel-control-prev-icon::after {
  content: "\f137";  /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
    position:absolute;
    font-size: 200%;
    color: #fff !important;
}




@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

    .switch-safari-only { 
        margin-top: 16px !important;
    }
}}
